<template>
  <Article
    :header-image="tagEditorCover"
    :title="title"
    header-text="ux design"
    section-type-name="ux design"
  >
    <template v-slot:body>
      <TagEditorBody />
    </template>
    <template v-slot:icon>
      <LumiIcon />
    </template>
  </Article>
</template>

<script>
import Article from "@/components/info/desktop/Article.vue";
import tagEditorCover from "@/assets/images/tag_editor/tags_cover.webp";
import { uxProjects } from "@/core/data/projects";
import TagEditorBody from "@/pages/ux/desktop/TagEditorBody.vue";
import LumiIcon from "@/assets/icon/lumi_icon.vue";

export default {
  name: "LuminosoTagEditor",
  components: { TagEditorBody, Article, LumiIcon },
  computed: {
    tagEditorCover: () => tagEditorCover,
    title: () => uxProjects.tagEditor.name,
  },
};
</script>

<style scoped></style>
