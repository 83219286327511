<template>
  <div>
    <div class="bottom-gap">
      <Role :roles="roles" />
    </div>
    <StagedFigureImage
      :is-on-dark="true"
      :src="tagEditorModal"
      alt="tag editor modal"
      cap="tag editor modal"
    />
    <Section :body="mandateBody" :title="mandateTitle" />
    <Section :body="definitionBody" :title="definitionTitle" />
    <Section :body="disconnectBody" :title="disconnectTitle" />
    <div class="top-gap">
      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :is-on-dark="false"
            :src="docLevel"
            alt="diagram of document-level analysis"
            cap="Document-level analysis"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
            :is-on-dark="false"
            :src="conceptLevel"
            alt="diagram of concept-level analysis"
            cap="Concept-level analysis"
          />
        </template>
      </Grid>
      <Section :body="uxResearchBody" :title="uxResearchTitle" />
      <Grid>
        <template v-slot:1>
          <Persona :title="managerTitle" :traits="managerTraits" />
        </template>
        <template v-slot:2>
          <Persona :title="marketerTitle" :traits="marketerTraits" />
        </template>
        <template v-slot:3>
          <Persona :title="scientistTitle" :traits="scientistTraits" />
        </template>
        <template v-slot:4>
          <Persona :title="developerTitle" :traits="developerTraits" />
        </template>
      </Grid>
      <Subtitle_List :body="uxLogisticsBody" :list="uxLogisticsList" />
      <Section :body="uxResultsBody" :title="uxResultsTitle" />
      <Section :body="strategyBody" :title="strategyTitle" />
      <Subtitle_List
        :body="previousBody"
        :list="previousList"
        :title="previousTitle"
      />
      <div class="top-gap">
        <InteractionVideo
          :video="previousFlow"
          caption="As you can see from the above,
          this is tiresome and in some cases discourages
          users from composing these boolean concepts."
        />
      </div>
      <Section :body="newFlowIntro" :title="newFlowTitle" />
      <Subtitle_List :list="newFlowList" />

      <div class="top-gap">
        <Subtitle_List :body="newFlowBody" />
      </div>
      <div class="top-gap">
        <InteractionVideo :video="newFlow" />
      </div>

      <Section :body="outcomesBody" :title="outcomesTitle" />
    </div>
  </div>
</template>

<script>
import tagEditorModal from "@/assets/images/tag_editor/tag_editor_modal.webp";
import StagedFigureImage from "@/components/info/desktop/figure/staged_figure.vue";
import { uxProjects } from "@/core/data/projects";
import Role from "@/components/info/desktop/Role.vue";
import Section from "@/components/info/desktop/Section.vue";
import docLevel from "@/assets/images/tag_editor/document_level_analysis.webp";
import conceptLevel from "@/assets/images/tag_editor/concept_level_analysis.webp";
import Grid from "@/components/info/desktop/figure/grid.vue";
import Persona from "@/components/info/desktop/Persona.vue";
import Subtitle_List from "@/components/info/desktop/Subtitle_List.vue";
import VideoPlayer from "@/components/info/VideoPlayer.vue";
import previousFlow from "@/assets/videos/tags/tags_prev.mp4";
import newFlow from "@/assets/videos/tags/tags_improved.mp4";
import InteractionVideo from "@/components/info/InteractionVideo.vue";

export default {
  name: "TagEditorBody",
  components: {
    InteractionVideo,
    VideoPlayer,
    Subtitle_List,
    Persona,
    Grid,
    Section,
    Role,
    StagedFigureImage,
  },
  computed: {
    docLevel: () => docLevel,
    conceptLevel: () => conceptLevel,
    tagEditorModal: () => tagEditorModal,
    previousFlow: () => previousFlow,
    newFlow: () => newFlow,
    roles: () => uxProjects.tagEditor.roles,
    mandateTitle: () => uxProjects.tagEditor.details.description.title,
    mandateBody: () => uxProjects.tagEditor.details.description.description,
    definitionTitle: () => uxProjects.tagEditor.details.sections[0].title,
    definitionBody: () => uxProjects.tagEditor.details.sections[0].intro,
    disconnectTitle: () => uxProjects.tagEditor.details.sections[1].title,
    disconnectBody: () => uxProjects.tagEditor.details.sections[1].intro,
    uxResearchTitle: () => uxProjects.tagEditor.details.sections[2].title,
    uxResearchBody: () => uxProjects.tagEditor.details.sections[2].intro,
    managerTitle: () =>
      uxProjects.tagEditor.details.sections[2].subsections[0].title,
    managerTraits: () =>
      uxProjects.tagEditor.details.sections[2].subsections[0].list,
    marketerTitle: () =>
      uxProjects.tagEditor.details.sections[2].subsections[1].title,
    marketerTraits: () =>
      uxProjects.tagEditor.details.sections[2].subsections[1].list,
    scientistTitle: () =>
      uxProjects.tagEditor.details.sections[2].subsections[2].title,
    scientistTraits: () =>
      uxProjects.tagEditor.details.sections[2].subsections[2].list,
    developerTitle: () =>
      uxProjects.tagEditor.details.sections[2].subsections[3].title,
    developerTraits: () =>
      uxProjects.tagEditor.details.sections[2].subsections[3].list,
    uxLogisticsBody: () => uxProjects.tagEditor.details.sections[3].intro,
    uxLogisticsList: () => uxProjects.tagEditor.details.sections[3].list,
    uxResultsTitle: () => uxProjects.tagEditor.details.sections[4].title,
    uxResultsBody: () => uxProjects.tagEditor.details.sections[4].intro,
    strategyTitle: () => uxProjects.tagEditor.details.sections[5].title,
    strategyBody: () => uxProjects.tagEditor.details.sections[5].intro,
    previousTitle: () => uxProjects.tagEditor.details.sections[6].title,
    previousBody: () => uxProjects.tagEditor.details.sections[6].intro,
    previousList: () => uxProjects.tagEditor.details.sections[6].list,
    newFlowTitle: () => uxProjects.tagEditor.details.sections[7].title,
    newFlowList: () => uxProjects.tagEditor.details.sections[7].list,
    newFlowIntro: () =>
      uxProjects.tagEditor.details.sections[7].subsections[0].intro,
    newFlowBody: () => uxProjects.tagEditor.details.sections[7].intro,
    outcomesTitle: () => uxProjects.tagEditor.details.sections[8].title,
    outcomesBody: () => uxProjects.tagEditor.details.sections[8].intro,
  },
};
</script>

<style scoped>
.bottom-gap {
  margin-block-end: 4rem;
}

.top-gap {
  margin-block-start: 4rem;
}
</style>
