<template>
  <div :class="['container', isDark ? 'border-dark' : 'border-light']">
    <header>
      <svg
        fill="none"
        height="20"
        viewBox="0 0 35 36"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :fill="myColors.main"
          clip-rule="evenodd"
          d="M17.5 4C14.5331 4 12.1849 6.35685 12.1849 9.19447C12.1849 12.0321 14.5331 14.3889 17.5 14.3889C20.4669 14.3889 22.8151 12.0321 22.8151 9.19447C22.8151 6.35685 20.4669 4 17.5 4ZM8.18494 9.19447C8.18494 4.08529 12.3869 0 17.5 0C22.6131 0 26.8151 4.08529 26.8151 9.19447C26.8151 14.3036 22.6131 18.3889 17.5 18.3889C12.3869 18.3889 8.18494 14.3036 8.18494 9.19447ZM0 32.7635C0 26.1647 5.42985 20.8717 12.0576 20.8717H22.9424C29.5702 20.8717 35 26.1647 35 32.7635C35 34.5822 33.5102 36 31.7428 36H3.2572C1.48978 36 0 34.5822 0 32.7635ZM4.0374 32H30.9626C30.5714 28.0248 27.1574 24.8717 22.9424 24.8717H12.0576C7.84262 24.8717 4.42864 28.0248 4.0374 32Z"
          fill-rule="evenodd"
        />
      </svg>

      <h3 :class="isDark ? 'dark-header' : 'light-header'">{{ title }}</h3>
    </header>
    <ul>
      <li
        v-for="item in traits"
        :class="[
          isDark ? 'dark-primary-li' : 'primary-li',
          isDark ? 'body-dark' : 'body-light',
        ]"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
import { store } from "@/store/store";
import { colors } from "@/core/colors.js";

export default {
  name: "Persona",
  store: store,
  props: {
    title: String,
    traits: [],
  },
  computed: {
    isDark() {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.isDark, store);
    },
  },
};
</script>

<style scoped>
.container {
  display: grid;
  inline-size: fit-content;
  place-items: start;
  margin-block: 2rem;
  gap: 1rem;
  padding-inline: 1rem;
  padding-block: 1rem;
  border-radius: 0.5rem;
}

.border-light {
  border: 1px solid var(--color-orange-5);
}

.border-dark {
  border: 1px solid var(--color-orange-4);
}

ul {
  display: grid;
  place-items: start;
  padding-inline-start: 1rem;
}

header {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr;
  place-content: center;
  place-items: center;
  gap: 1rem;
}

h3 {
  font-size: large;
  font-weight: 500;
}
</style>
